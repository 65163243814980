body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
  /* background-color: #131516;*/
  background-color: #0f1111;
  color: white;
  width: 100%;
}

input[type="radio"] {
  -webkit-appearance: radio;
}

strong,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  cursor: default;
}


.site-container {
  min-height: 100vh;
}

main {
  flex: 1;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.rating span {
  color: #ffc000;
  text-align: left;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}

.product img {
  width: 100%;
  max-width: 400px;
}

.product-info {
  padding: 1rem;
}

.img-large {
  max-width: 100%;
}

.img-thumbnail {
  height: 80px;
}

.small-container {
  max-width: 600px;
}

input {
  text-align: right;
  background-color: rgb(24 26 27);
  color: rgb(60 65 68);
}

.checkout-steps>div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}

.checkout-steps>div>a {
  text-decoration: none;
  color: #a0a0a0;
}

.checkout-steps>div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
  text-decoration: none;
}

.checkout-steps>div.active>a {
  text-decoration: none;
  color: #f08000;
}

.thumbnail>.card-img-top {
  height: 100px !important;
}

.card-img-top {
  object-fit: cover;
  height: 420px !important;
}

.navnav {
  /* background-color: #1c1e1f; */
  background-color: #161819;
}

/* sidebar */
.navbar-brand {
  font-weight: bold;
}

/* .side-navbar {
  width: 240px;
  height: 36rem;
  position: absolute;
  left: -300px;
  top:56px;
  background-color: #100901;
  transition: 0.5s;
  z-index:  100;
} */

.side-navbar {
  width: 240px;
  height: 23rem;
  position: absolute;
  left: -300px;
  top: 70px;
  background-color: #100901;
  transition: 0.5s;
  z-index: 100;
  border-radius: 0px 15px 15px 0px;
}

.site-container {
  transition: 0.4s;
}

.active-cont {
  position: relative;
}

.active-nav {
  left: 0;
}

.text-bold {
  font-weight: bold;
}

/* Map */

.full-box {
  height: 100vh;
  max-width: initial;
}

.full-box header,
.full-box footer {
  display: none;
}

.full-box .container {
  height: 100vh;
  max-width: initial;
  margin: 0;
  padding: 0;
}

.full-box .mt-3 {
  margin-top: 0 !important;
}

.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 3rem;
  display: flex;
}

.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}

.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}

button.thumbnail {
  padding: 0;
}

#deleteImage {
  border-radius: 3px 3px 0px 0px;
  color: rgb(249, 109, 109);
}

#search-button {
  background-color: rgb(24 26 27);
  color: rgb(60 65 68);
}

#button-search {
  border: solid 1px #0147ae;
  border-right: none;
  box-shadow: none;
  height: 39px;
}

.card-body {
  background-color: #181a1b;
  border-radius: 0px 0px 3px 3px;
  color: #b3b1a2;
}

.mdb-card{
  border:none;
}

.hrhr {
  width: 100%;
  height: 1px;
  background-color: rgb(110, 110, 110);
}

.fa-search {
  color: white;
}

#q {
  border: solid 1px rgb(60 65 68 / 1);
  background-color: rgb(24 26 27);
  color: white;
  box-shadow: none;
  height: 39px;
}

#q:focus {
  box-shadow: none;
}

#products-search-col {
  margin-top: -380px;
}

.productCard,
option,
textarea {
  text-align: right;
}

.mb-3 {
  color: white;
}

.table {
  color: white;
}

.order-details {
  color: black;
}

textarea {
  resize: none;
  height: 150px;
}

#freeShippingLabel {
  margin-right: 185px;
  margin-bottom: -50px;
}

#shippingLabel {
  margin-left: -575px;
}

#shippingLabel2 {
  margin-right: 380px;
}

.fa-whatsapp,
.fa-facebook {
  color: gray;
}


.fa-whatsapp:hover,
.fa-facebook:hover {
  color: yellowgreen;
}

.drop-menu {
  margin-top: -2px !important;
  border-radius: 3px 0px 3px 0px !important;
}

.mb-3,
.mb-3>.form-label {
  color: white;
}


.mb-3>.form-control,
.mb-3>.form-control:focus,
.mb-3>.form-control:active,
.text-end>.form-select,
.text-end>.form-select:focus,
.mb-3>.form-select:focus,
.mb-3>.form-select {
  border: solid 1px rgb(60 65 68 / 1);
  background-color: rgb(24 26 27);
  box-shadow: none;
  color: white;
}

.btn.btn:disabled {
  box-shadow: none;
}

.btn.btn {
  box-shadow: none;
}

.btn.btn-light:active,
.btn.btn-light:focus,
.btn.btn-light:hover {
  box-shadow: none;
}

.btn.btn-primary {
  box-shadow: 0px 3px 7px rgba(80, 100, 255, 0.411);
}

table,
tr {
  color: white;
}


/* @media only screen and (max-width: 767px) {

  .table {

    width: 600px;
    margin-left: -50px;
  }

}

@media only screen and (max-width: 650px) {

  .table {

    width: 500px;
    margin-left: 5px;
    font-size: 13px;
  }

  #button-table {
    font-size: 10px;
    width: 50px;
  }

  #table-order-id {
    color: red;
  }
}

@media only screen and (max-width: 556px) {

  .table {

    width: 430px;
    margin-left: 25px;
    font-size: 11px;
  }

  #button-table {
    font-size: 10px;
    width: 50px;
  }

}

@media only screen and (max-width: 530px) {
  .table {

    width: 430px;
    margin-left: 25px;
    table-layout: fixed;
    font-size: 10px;
  }

  #button-table {
    font-size: 10px;
    width: 50px;
  }

  #table-order-id {
    margin-left: -15px !important;
    font-size: 5px;
  }
}

@media only screen and (max-width: 500px) {

  .table {
    width: 430px;
    margin-left: 25px;
    table-layout: fixed;
    font-size: 10px;
  }

  #button-table {
    font-size: 10px;
    width: 50px;
  }

  #table-order-id {
    margin-left: -15px !important;
    font-size: 5px;
  }

  #q{
    width: 40px;
    height: 40px;
    font-size:7px;
  }
  #button-search{
    height: 40px;
    width: 10px;
  }

  .fas.fa-bars,#sidebar-btn {
    display: none;
  }
}

@media only screen and (max-width: 474px) {

  .table {
    width: 380px;
    margin-left: -30px;
    table-layout: fixed;
    font-size: 7px;
  }

  #table-order-id {
    margin-left: -15px !important;
    font-size: 3px;
  }
} */

@media only screen and (max-width:1420px) {

  .home-carousel {
    width: 600px !important;
  }
}

@media only screen and (max-width:1200px) {

  .home-carousel {
    width: 440px !important;
  }
}

@media only screen and (max-width:991px) {

  #products-search-col {
    margin-top: 0;
  }

  .home-carousel {
    width: 300px !important;
    margin-left: -100px !important;
  }
}


@media only screen and (max-width:767px) {

  #navbar-brand {
    margin-right: -110px !important;
  }

  #basic-navbar-nav {
    margin-left: -120px !important;
  }

  .home-carousel {
    width: 400px !important;
    margin-left: 93px !important;
  }

}

@media only screen and (max-width:767px) {

  #navbar-brand {
    margin-right: -90px !important;
  }

  #basic-navbar-nav {
    margin-left: -100px !important;
  }

}

@media only screen and (max-width:720px) {

  #navbar-brand {
    margin-right: -70px !important;
  }

  #basic-navbar-nav {
    margin-left: -80px !important;
  }

}


@media only screen and (max-width:680px) {

  #navbar-brand {
    font-size: 15px;
    margin-right: -50px !important;
  }

  #basic-navbar-nav {
    font-size: 15px;
    margin-left: -60px !important;
  }

}

@media only screen and (max-width:640px) {

  #navbar-brand {
    font-size: 14px;
    margin-right: -30px !important;
  }

  #basic-navbar-nav {
    font-size: 14px;
    margin-left: -50px !important;
  }

}

@media only screen and (max-width:640px) {

  #navbar-brand {
    font-size: 14px;
    margin-right: -10px !important;
  }

  #basic-navbar-nav {
    font-size: 14px;
    margin-left: -50px !important;
  }

  #q {
    width: 150px;
  }

  #sidebar-btn {
    margin-left: 15px;
  }

}


@media only screen and (max-width:600px) {


  #q {
    width: 140px;
  }

  #sidebar-btn {
    margin-left: 25px;
  }

}

@media only screen and (max-width:575px) {

  #navbar-brand {
    margin-right: 25px !important;
  }

  #sidebar-btn {
    margin-left: 45px;
  }

  #quan-item {
    margin-left: 12px !important;
  }

}



@media only screen and (max-width:551px) {

  #q {
    font-size: 11px;
    width: 100px;
  }


}

@media only screen and (max-width:540px) {

  .home-carousel {
    width: 400px !important;
    margin-left: 40px !important;
  }


}


@media only screen and (max-width:511px) {

  .fas.fa-bars,
  #mdb-bars {
    display: none;
  }

  #search-button {
    margin-left: 45px !important;
  }
  
  .product-card,.home-products{
    height:300px;
  }

  .card-img-top{
    object-fit:scale-down;
  }

}

@media only screen and (max-width: 474px) {

  .table {
    width: 380px;
    margin-left: -30px;
    table-layout: fixed;
    font-size: 7px;
  }

  #table-order-id {
    margin-left: -15px !important;
    font-size: 3px;
  }


  #button-table {
    font-size: 6px;
    width: 50px;
    height: 25px;

  }

  .home-carousel {
    width: 400px !important;
    margin-left: 15px !important;
  }

}

@media only screen and (max-width:471px) {

  #q {
    font-size: 9px;
    width: 75px;
  }

  #button-search {
    font-size: 7px;
  }

  #product-name-title {
    font-size: 13px;
  }


}

@media only screen and (max-width:453px) {


  .home-carousel {
    width: 300px !important;
    margin-left: 55px !important;
  }

}

@media only screen and (max-width:435px) {

  #q {
    font-size: 7.5px;
    width: 65px;
  }

}

@media only screen and (max-width:425px) {

  #button-search {
    width: 7.5px;
    height: 20px;
  }

  #q {
    width: 50px;
    height: 20px;
  }

  #search-button {
    /* width: 50px; */
    margin-top: 10px;
    height: 20px;
  }

  .fa-search {
    margin-bottom: -5px !important;
    margin-left: -3px;
  }

}

@media only screen and (max-width:410px) {

  #quan-item {
    margin-left: 12px !important;
  }


}

@media only screen and (max-width:401px) {

  #navbar-brand {
    margin-right: -5px !important;
    font-size: 13px;
  }

  #basic-navbar-nav {
    font-size: 13px;
    margin-left: -5px !important;
  }

  #button-search {
    margin-left: -45px !important;
  }

  .home-carousel {
    margin-left: 25px !important;
  }

}

@media only screen and (max-width:373px) {

  #q {
    height: 20px;
    width: 35px;
    font-size: 7px;
  }

  .home-carousel {
    margin-left: 10px !important;
  }

}

@media only screen and (max-width:358px) {

  #navbar-brand {
    margin-left: 5px !important;
    font-size: 10px;
  }

  #q {
    height: 20px;
    width: 30px;
    font-size: 7px;
  }

  .home-carousel {
    margin-left: 0px !important;
  }

}

@media only screen and (max-width:280px) {

  #navbar-brand {
    margin-left: -5px !important;
    font-size: 10px;
  }

  #basic-navbar-nav {
    font-size: 10px;
    margin-left: -45px !important;
  }

  #q {
    height: 20px;
    width: 50px;
    font-size: 7px;
    border-radius: 3px;
  }

  #button-search {
    display: none;
  }

  .home-carousel {
    width: 220px !important;
    margin-left: 0px !important;
  }

}